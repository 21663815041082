import core from '@/admin/core';

interface CounterResponse {
  counterId: string;
  totalCount: number;
  maxCount: number;
}
export type CounterProps = Omit<CounterResponse, 'counterId'>;

export const getCounters = async () => {
  const result = await core.httpClient.get('/admin/public/counters');
  return result.data as CounterResponse[];
};

export const createCounter = async (props: CounterProps) => {
  await core.httpClient.post('/admin/public/counters', props);
};

export const editCounter = async (counterId: string, props: CounterProps) => {
  await core.httpClient.put(`/admin/public/counters/${counterId}`, props);
};
