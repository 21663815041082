














































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { useNotification } from '@/composition/notification';
import { useRelease } from '@/composition/release';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcNumberForm from '@/components/FcNumberForm.vue';

export default defineComponent({
  name: 'Release',
  components: {
    FcRoleLoading,
    FcNumberForm,
  },
  setup() {
    const myRoleCounterSettings = computed(() => myAttributes.getRoleSettings('counters'));
    const myRoleBasicAuthSettings = computed(() => myAttributes.getRoleSettings('basicAuth'));

    const { release } = useRelease();

    const isSaving = ref(false);
    const isLoading = computed(() => !release.counter);
    const notification = useNotification();
    const { confirmDialog } = useConfirm();

    const saveCounter = async () => {
      if (!(await confirmDialog('会員番号を変更していいですか？'))) return;
      isSaving.value = true;
      try {
        await release.saveCounter();
        notification.notify('変更しました');
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };

    const saveBasicAuthenticator = async () => {
      if (!(await confirmDialog(`Basic認証を${release.isRelease ? '解除' : '適応'}してよいですか？`))) return;
      isSaving.value = true;
      try {
        await release.saveBasicAuthenticated();
        notification.notify('変更しました');
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };

    return {
      pageTitle: 'リリース設定',
      myRoleCounterSettings,
      myRoleBasicAuthSettings,
      isSaving,
      isLoading,
      saveCounter,
      saveBasicAuthenticator,
      release,
    };
  },
});
