import { getCounters, editCounter, createCounter, CounterProps } from '@/admin/membershipCounters';
import { getBasicAuthentication, updateBasicAuthentication } from '@/admin/basicAuthentication';
import { reactive } from '@vue/composition-api';

class Release {
  counter: CounterProps | null = null;
  counterId = 'DefaultUserNumber';
  _totalCount = 0; //保存前の値

  isRelease = true;
  _isRelease = true; //保存前の値

  constructor() {
    Promise.all([getCounters(), getBasicAuthentication()]).then(([counters, isActiveBasicAuthentication]) => {
      const defaultCounter = counters.find((item) => this.counterId === item.counterId);
      if (defaultCounter) {
        this.counter = {
          totalCount: defaultCounter.totalCount,
          maxCount: defaultCounter.maxCount,
        };
        this._totalCount = this.counter.totalCount;
      }
      this.isRelease = !isActiveBasicAuthentication;
      this._isRelease = !isActiveBasicAuthentication;
    });
  }

  createCounterProps = () => {
    if (!this.counter) throw '会員番号の取得に失敗しました';
    else if (this._totalCount === this.counter.totalCount) throw '変更してから保存してください';
    return this.counter;
  };
  saveCounter = async () => {
    const props = this.createCounterProps();
    await editCounter(this.counterId, props);
    this._totalCount = props.totalCount;
  };
  createBasicAuthenticatedProps = () => {
    if (this._isRelease === this.isRelease) throw '変更してから保存してください';
    return {
      isActive: !this.isRelease,
    };
  };
  saveBasicAuthenticated = async () => {
    const props = this.createBasicAuthenticatedProps();
    await updateBasicAuthentication(props);
    this._isRelease = !props.isActive;
  };
}

export const useRelease = () => {
  const release = new Release();
  return { release: reactive(release) };
};
