import core from '@/admin/core';

interface BasicAuthentication {
  isActive: boolean;
}

export const getBasicAuthentication = async () => {
  const result = await core.httpClient.get('/admin/public/basicAuth');
  return result.data.isActive as boolean;
};
export const updateBasicAuthentication = async (props: BasicAuthentication) => {
  await core.httpClient.put('/admin/public/basicAuth', props);
};
