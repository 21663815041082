

















import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'FcNumberForm',
  props: {
    value: {
      // Stringで渡ってくる際、Numberで送り返すとエラーになるので両方許容する
      type: [String, Number],
      require: true,
      default: 0,
    },
    max: {
      type: Number || null,
      require: false,
      default: null,
    },
    min: {
      type: Number || null,
      require: false,
      default: null,
    },
    prefix: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  setup(props, context) {
    // 初期値をNumberにして送り返す
    context.emit('input', Number(props.value));

    const inputValue = ref(Number(props.value));
    const errorMessage = ref('');
    const maxValue = props.max === null ? null : Number(props.max);
    const minValue = props.min === null ? null : Number(props.min);
    watch(() => props.value, () => inputValue.value = Number(props.value));
    watch(
      () => inputValue.value,
      () => {
        const value = Number(inputValue.value);
        errorMessage.value = '';
        if (maxValue !== null && maxValue < value) errorMessage.value = '設定できる範囲外です';
        else if (minValue !== null && value < minValue) errorMessage.value = '設定できる範囲外です';
        context.emit('input', value);
      }
    );
    return { inputValue, errorMessage, maxValue, minValue };
  },
});
